import { Nullable } from "@shared/models/general";

export interface ContentTypeChipProps {
  label: string;
  fullWidth?: boolean;
  chipType?: string;
}
export interface BusinessSegmentsProps {
  segments: any[];
}

export interface InsightsMainContentProps {
  id: string;
  url: string;
  title: string;
  publishDate: string;
  description: Nullable<string>;
  contentType: Nullable<string>;
  authorName: string;
}
export const enum ContentButtonSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}
