const parsedate = (date) => (date ? new Date(date) : null);

const comparedate = (pre, curr) => {
  const prePublised_date = parsedate(pre.publised_date);
  const currPublised_date = parsedate(curr.publised_date);
  const prepublishDate = parsedate(pre.publishDate);
  const currpublishDate = parsedate(curr.publishDate);

  if (prePublised_date && currPublised_date) return currPublised_date - prePublised_date;
  if (prePublised_date) return -1;
  if (currPublised_date) return 1;
  return currpublishDate - prepublishDate;
};

export const sortedObjectsByDates = (obj) => {
  return obj.sort(comparedate);
};
