import { DataGridInsightsColumn, InsightHubDataGridColumnsProps } from "@insightsHub/models/dataGrid.models";

export const getContentLibDataGridColumns = ({
  handleReadMore,
  isXsmall,
}: InsightHubDataGridColumnsProps): Array<DataGridInsightsColumn> => {
  const contentLibraryColumns: Array<DataGridInsightsColumn> = [
    {
      accessor: "publishDate",
      Header: "DATE PUBLISHED",
      flex: 1.5,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      withTooltip: true,
      minWidth: isXsmall ? screen.availWidth - 210 : 110,
    },
    {
      accessor: "authorName",
      Header: "TITLE AND AUTHOR",
      flex: 3,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      type: "author",
      withTooltip: true,
      minWidth: isXsmall ? 320 : 120,
      maxWidth: isXsmall ? 320 : 250,
    },
    {
      accessor: "businessSegment",
      Header: "BUSINESS SEGMENT",
      flex: 2,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      withTooltip: true,
      minWidth: isXsmall ? 220 : 150,
    },
    {
      accessor: "contentType",
      Header: "FORMAT",
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      withTooltip: true,
      minWidth: 130,
    },
    {
      accessor: "learnMore",
      flex: 2,
      Header: "",
      width: 35,
      align: "left",
      headerAlign: "left",
      type: "learnMore",
      minWidth: 200,
      onClick: ({ original }) => handleReadMore(original),
    },
  ];

  return contentLibraryColumns;
};
