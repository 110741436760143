import React, { FunctionComponent, useCallback, useMemo } from "react";
import cn from "classnames";
import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderNavigationTargetPage, MenuOptions } from "@app/models/Header";
import { ReactComponent as HelpIcon } from "@assets/svg/faq_icon.svg";
import { DISABLED_ADMIN_FEATURES_MESSAGE } from "@app/constants/messages";
import { ReactComponent as SettingsIcon } from "@assets/svg/settings_icon.svg";
import { ReactComponent as EmailIcon } from "@assets/svg/email_icon.svg";
import { openInvestorRequestDialog } from "@investorRequest/store/ducks";
import ProfileDropdown from "@app/components/ProfileDropdown";
import { AppRoute } from "@app/models/AppRoute";
import gtag from "@utils/gtag";
import { useAppDispatch, useAppSelector, useHelpLink } from "@app/hooks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import useAppProfile from "../hooks/useAppProfile";

interface Props {
  mobile?: boolean;
  helpIconClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.grey[500],
    cursor: "pointer",
    transition: "color .15s",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  iconButtonDisabled: {
    cursor: "default",
    color: "rgba(255, 255, 255, 0.25)",
    "&:hover": {
      color: "rgba(255, 255, 255, 0.25)",
    },
  },
}));

const AppToolbarIcons: FunctionComponent<Props> = ({ mobile, helpIconClick }) => {
  const helpAnchor = useHelpLink();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { user: impersonatedUserData } = useAppSelector(impersonationStateSelector);

  const { canImpersonate, isImpersonating, userName, handleProfileMenuClick, handleSignOut } = useAppProfile();

  const isAdministrationButtonVisible = useMemo(
    () => (isImpersonating ? impersonatedUserData?.isAdmin : canImpersonate),
    [canImpersonate, impersonatedUserData?.isAdmin, isImpersonating]
  );

  const isAdministrationButtonDisabled = useMemo(
    () => isImpersonating && !impersonatedUserData?.isAdmin,
    [impersonatedUserData?.isAdmin, isImpersonating]
  );

  const openAdminControlPage = useCallback(() => {
    if (!isAdministrationButtonVisible || isAdministrationButtonDisabled) {
      return;
    }

    gtag("event", "navigation_menu_click", {
      target_page: HeaderNavigationTargetPage.AdminControl,
    });

    history.push(AppRoute.AdministrationBase);
  }, [history, isAdministrationButtonDisabled, isAdministrationButtonVisible]);

  const openHelpPage = useCallback(() => {
    if (helpIconClick) {
      helpIconClick();
    }

    gtag("event", "navigation_menu_click", {
      target_page: HeaderNavigationTargetPage.Help,
    });

    history.push(helpAnchor);
  }, [history, helpAnchor, helpIconClick]);

  return (
    <>
      <Grid item>
        <Tooltip title={<Typography>{MenuOptions.Help}</Typography>} placement="top">
          <HelpIcon className={classes.iconButton} onClick={openHelpPage} data-testid="header-help-icon" />
        </Tooltip>
      </Grid>
      {isAdministrationButtonVisible && (
        <Grid item>
          <Tooltip
            title={
              <Typography>
                {isAdministrationButtonDisabled ? DISABLED_ADMIN_FEATURES_MESSAGE : MenuOptions.Administration}
              </Typography>
            }
            placement="top"
          >
            <SettingsIcon
              className={cn(classes.iconButton, isAdministrationButtonDisabled && classes.iconButtonDisabled)}
              onClick={openAdminControlPage}
            />
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip
          title={<Typography>{MenuOptions.InvestorRequest}</Typography>}
          placement="top"
          disableHoverListener={isImpersonating}
        >
          <EmailIcon
            className={cn(classes.iconButton, isImpersonating && classes.iconButtonDisabled)}
            onClick={() => (isImpersonating ? noop() : dispatch(openInvestorRequestDialog()))}
            data-testid="investor-request-icon"
          />
        </Tooltip>
      </Grid>
      {!mobile && (
        <Grid item>
          <ProfileDropdown
            name={userName}
            canImpersonate={canImpersonate}
            isImpersonating={isImpersonating}
            iconClassName={classes.iconButton}
            onMenuOptionClick={handleProfileMenuClick}
            onSignOutButtonClick={handleSignOut}
          />
        </Grid>
      )}
    </>
  );
};

export default AppToolbarIcons;
