import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InsightsHubArticle, InsightsHubState } from "@insightsHub/models";
import { fetchInsightsHubArticles } from "@insightsHub/store/thunks";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { timeRangeOptions } from "@insightsHub/constants";

const initialState: InsightsHubState = {
  data: null,
  filters: {
    timeRange: {
      timePeriod: timeRangeOptions[0],
      start: null,
      end: null,
    },
    selectedFormat: null,
    selectedAuthor: null,
    selectedBusinessSegment: null,
    searchInputKeyword: "",
  },
  loading: false,
  error: null,
};

const insightsHubSlice = createSlice({
  name: "insightsHub",
  initialState,
  reducers: {
    resetState: () => initialState,
    updateInsightFilters: (state, action: PayloadAction<Partial<InsightsHubState["filters"]>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsightsHubArticles.pending, (state) => ReduxUtils.defaultPendingActionHandler(state))
      .addCase(fetchInsightsHubArticles.fulfilled, (state, action) =>
        ReduxUtils.defaultFulfilledActionHandler(
          state,
          action.payload.data.map(
            (articleDto): InsightsHubArticle => ({
              id: articleDto.id,
              authorName: articleDto.attributes.Author_Name ?? "",
              authorTitle: articleDto.attributes.Author_Title ?? "",
              authorName2: articleDto.attributes.Author_Name2 ?? "",
              authorTitle2: articleDto.attributes.Author_Title2 ?? "",
              authorName3: articleDto.attributes.Author_Name3 ?? "",
              authorTitle3: articleDto.attributes.Author_Title3 ?? "",
              title: articleDto.attributes.Title ?? "N/A",
              content: articleDto.attributes.Content ?? "N/A",
              shortDescription: articleDto.attributes.ShortDescription ?? "",
              publishDate: articleDto.attributes.publishedAt ?? "",
              publised_date: articleDto.attributes.Publised_Date ?? "",
              contentType: articleDto.attributes.Content_Type ?? "",
              contentImage: articleDto.attributes.Content_Image?.data?.attributes.url,
              homepageImage: articleDto.attributes.Homepage_Image.data?.attributes.url,
              businessSegmenstMultiSelect: articleDto.attributes.Business_Segment_Type,
              featuredArticle: articleDto.attributes.Featured_Article ?? false,
              articleType: articleDto.attributes.Article_Type ?? "",
            })
          )
        )
      )
      .addCase(fetchInsightsHubArticles.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state, action.payload as string)
      );
  },
});

export const { resetState, updateInsightFilters } = insightsHubSlice.actions;
export default insightsHubSlice.reducer;
