import { AppThunkAPI } from "@shared/models/redux";

type CallTrackInsightsHubArticleAccessAPI = (args: {
  articleId: string;
  articleTitle: string;
  articleAuthor: string;
  articleAuthorTitle: string;
  articleAuthor2: string;
  articleAuthorTitle2: string;
  articleAuthor3: string;
  articleAuthorTitle3: string;
  contentType: string;
  businessSegment: string;
}) => AppThunkAPI<Promise<unknown>>;

export const callTrackInsightsHubArticleAccessAPI: CallTrackInsightsHubArticleAccessAPI =
  ({
    articleId,
    articleTitle,
    articleAuthor,
    articleAuthorTitle,
    articleAuthorTitle2,
    articleAuthor2,
    articleAuthorTitle3,
    articleAuthor3,
    contentType,
    businessSegment,
  }) =>
  async (_dispatch, _state, extra) => {
    await extra(`documents/v1/InsightsHubTracking/${articleId}`, {
      method: "post",
      body: {
        articleTitle,
        articleAuthor,
        articleAuthorTitle,
        articleAuthor2,
        articleAuthorTitle2,
        articleAuthor3,
        articleAuthorTitle3,
        contentType,
        businessSegment,
      },
    });
  };
