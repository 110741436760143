import { AsyncSlice } from "@shared/models/redux";
import { Nullable } from "@shared/models/general";
import { Currency } from "@shared/models/currency";

export interface AccountGroupBase {
  entitledUserId: number;
  groupId: number;
  groupName: string;
}

export interface AccountGroupResponse extends AccountGroupBase {
  investorAccounts: {
    id: string;
    globalId: string; // e.g. IA000003699
    investorName: string;
  }[];
}

export interface AccountGroup extends AccountGroupBase {
  investorAccountIds: number[];
}

export interface Investor {
  class: string;
  currency: Nullable<string>;
  description: Nullable<string>;
  investorId: string;
  investorName: string;
  lastUpdatedDatetime: Nullable<string>;
  parentId: Nullable<string>;
  region: string;
  type: Nullable<string>;
}

export interface Investors {
  [key: string]: Investor;
}

export interface EntitledAccount {
  id: number;
  accountType: string; // @TODO define enum
  name: string;
  globalId: string; // e.g. IA000003699
  fundGlobalId: Nullable<string>;
  externalId: string;
}

// @todo Add remaining fundraising statuses
export enum FundraisingStatus {
  Fundraising = "Fundraising",
  Closed = "Closed",
}

// @todo Add remaining product lines
export enum ProductLine {
  NonInvestment = "Non-Investment",
  External = "External",
}

export interface EntitledFundResponse {
  acronym: string;
  collapseInDmltFeed: boolean;
  entityDisplayName: Nullable<string>;
  entityName: string;
  fundraisingStatus: Nullable<FundraisingStatus>;
  globalId: string; // e.g. F000000021
  id: number;
  includeInDmltFeed: boolean;
  isAdmin: boolean;
  notMdmValidated: boolean;
  parentEntitledEntity: Nullable<EntitledFundResponse>;
  parentEntitledEntityId: Nullable<number>;
  processArea: string; // @TODO define enum
  productFamily: Nullable<string>;
  productLine: Nullable<ProductLine>;
  productSegment: string;
  productType: Nullable<string>; // @TODO define enum
  eventActivationDate: Nullable<string>;
  endDate: Nullable<string>;
  currentDate: Nullable<string>;
}

export interface EntitledFund extends EntitledFundResponse {
  entitledAccountIds: Nullable<Array<EntitledAccount["globalId"]>>;
}

export interface DocumentSubtype {
  documentSubtypeId: number;
  documentTypeId: number;
  name: string;
}

export interface DocumentType {
  documentTypeId: number;
  name: string;
  displayName: Nullable<string>;
  documentSubtypes: DocumentSubtype[];
}

export interface Fund {
  id: string;
  name: string;
  parent: Nullable<string>;
  managerName: Nullable<string>;
  size: number;
  vintage: number;
  currency: Currency;
  geography: string;
  status: string;
  strategy: string;
  industry: Nullable<string>;
  type: string;
  investmentPeriodOriginal: string;
  investmentPeriodCurrent: string;
  termExpirationOriginal: string;
  termExpirationCurrent: string;
  firstClose: string;
  assetClass: string;
  managementFeesPctCommitmentPeriod: number;
  carriedIntPct: number;
  hurdleRatePct: number;
  fundAbbreviation: string;
  followOnPeriodOriginal: string;
  followOnPeriodCurrent: string;
  periodBasisCommitmentPeriod: string;
  managementFeesPctPostCommitmentPeriod: number;
  periodBasisPostCommitmentPeriod: string;
  catchUpSplitByGpLp: string;
  fundPeriodClassification: string;
  carlyleCommitment: number;
  grossIrr: number;
  netIrr: number;
  irrAsOfDate: string;
  feeRebatePctCommitmentPeriod: number;
  feeRebatePctPostCommitmentPeriod: number;
  fundStatus: string;
  strategyDescription: Nullable<string>;
  endDate: Nullable<string>;
  currentDate: Nullable<string>;
}

export interface Funds {
  [key: string]: Fund;
}

export interface EntitiesState {
  entitledAccountsByFundLoading: boolean;
  entitledAccounts: AsyncSlice<Record<EntitledAccount["globalId"], EntitledAccount>>;
  entitledFunds: AsyncSlice<Record<EntitledFund["globalId"], EntitledFund>>;
  entitledValidFunds: AsyncSlice<Record<EntitledFund["globalId"], EntitledFund>>;
  documentTypes: AsyncSlice<Record<DocumentType["documentTypeId"], DocumentType>>;
  accountGroups: AsyncSlice<AccountGroup[]>;
  investors: AsyncSlice<Investors>;
  funds: AsyncSlice<Funds>;
}
