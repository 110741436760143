import React, { memo, useCallback, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, Link, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getFooterLinks } from "@app/utils/getFooterLinks";
import { useHelpLink } from "@app/hooks";
import { Container } from "@shared/components";
import { useGetUserPermissions } from "@user/hooks";
import { footerSocialLinks } from "@app/constants/footerSocialLinks";
import { getNameInKebabCase } from "@tests/utils";
import { FooterLink } from "@app/models/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(30, 0),
    color: theme.palette.grey["500"],
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(10, 0),
    },
  },
  linksContainer: {
    display: "flex",
    flexFlow: "column wrap",
    [theme.breakpoints.down("md")]: {
      flexWrap: "nowrap",
    },
  },
  link: {
    flexBasis: "50%",
    textTransform: "uppercase",
    fontSize: 15,
    lineHeight: 1,
    cursor: "pointer",
    color: "inherit",
    transition: "color .15s",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2.5),
      flexBasis: "100%",
      fontSize: 12,
      textAlign: "center",
    },
    "&:hover": {
      color: theme.palette.common.white,
      textDecoration: "none",
    },
  },
}));

const AppFooter = () => {
  const classes = useStyles();

  const helpLink = useHelpLink();
  const userPermissions = useGetUserPermissions();

  const links = useMemo(() => getFooterLinks(userPermissions), [userPermissions]);

  const renderLink = useCallback(
    ({ isActive, label, getLink }: FooterLink) => {
      if (!isActive) return null;

      const link = getLink(helpLink);

      const optionalProps = {
        component: RouterLink,
        to: link,
      };

      return (
        <Link
          {...optionalProps}
          key={label}
          className={classes.link}
          data-testid={`${getNameInKebabCase(label)}-link`}
          underline="hover"
        >
          {label}
        </Link>
      );
    },
    [classes.link, helpLink]
  );

  return (
    <Box component="footer" className={classes.root}>
      <Container>
        <Grid container direction="row" justifyContent="space-between" spacing={10}>
          <Grid item component="nav" xs={12} lg={8} xl={6} className={classes.linksContainer}>
            {links.map(renderLink)}
          </Grid>
          <Grid item container xs={12} lg={4} xl={6} justifyContent={{ xs: "center", lg: "flex-end" }}>
            <Grid item container spacing={8} justifyContent={{ xs: "center", lg: "flex-end" }}>
              {footerSocialLinks.map(({ label, link, Icon }) => (
                <Grid item xs="auto" key={label}>
                  <Tooltip title={<Typography>{label}</Typography>} placement="top">
                    <Link
                      href={link}
                      rel="noreferrer"
                      target="_blank"
                      color="inherit"
                      sx={{ transition: "color .15s", "&:hover": { color: "common.white" } }}
                    >
                      <Icon />
                    </Link>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
            <Grid item fontSize={14} textAlign={{ xs: "center", lg: "end" }}>
              2021&nbsp;Carlyle&nbsp;Investment&nbsp;Management&nbsp;L.L.C. All&nbsp;rights&nbsp;reserved
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default memo(AppFooter);
