import { createSelector } from "@reduxjs/toolkit";
import { generatePath } from "react-router-dom";
import IAGroupUtils from "@shared/utils/IAGroupUtils";
import { accountGroupsSelector, entitledValidEndDateFundsSelector, fundsSelector } from "@store/Entities/selectors";
import {
  loggedInSelectedIAGroupIdSelector,
  loggedInUserPreferencesSelector,
  userSliceSelector,
} from "@user/store/selectors";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { IAGroupOption } from "@app/models/Header";
import { AppRoute } from "@app/models/AppRoute";

export const preferencesSelector = createSelector(
  loggedInUserPreferencesSelector,
  impersonationStateSelector,
  (loggedInUserPreferences, impersonationState) => {
    if (impersonationState.isImpersonating) {
      return impersonationState.user!.preferences;
    }

    return loggedInUserPreferences;
  }
);

export const selectedIAGroupIdSelector = createSelector(
  loggedInSelectedIAGroupIdSelector,
  impersonationStateSelector,
  (loggedInSelectedIAGroupId, impersonationState) => {
    if (impersonationState.isImpersonating) {
      return impersonationState.user!.selectedAccountGroup;
    }

    return loggedInSelectedIAGroupId;
  }
);

export const investorAccountGroupsListOptionsSelector = createSelector(accountGroupsSelector, (groupsSlice) =>
  groupsSlice.data?.map((g) => ({
    id: g.groupId,
    label: g.groupName,
  }))
);

export const appHeaderBarSelector = createSelector(
  accountGroupsSelector,
  investorAccountGroupsListOptionsSelector,
  userSliceSelector,
  impersonationStateSelector,
  selectedIAGroupIdSelector,
  ({ loading: investorAccountGroupsLoading }, groups, userSlice, impersonation, selectedAccountGroup) => {
    let selectedIAGroup: IAGroupOption = IAGroupUtils.defaultIAGroupOptions[0];

    const userName = impersonation.isImpersonating ? impersonation.user!.name : userSlice.data!.name;

    if (selectedAccountGroup && groups) {
      selectedIAGroup = groups.find((group) => group.id === selectedAccountGroup)!;
    }

    return {
      userName,
      selectedIAGroup,
      investorAccountGroups: IAGroupUtils.getHeaderIAGroupOptions(groups),
      investorAccountGroupsLoading,
    };
  }
);

export const appNavigationFundsSelector = createSelector(
  fundsSelector,
  entitledValidEndDateFundsSelector,
  (fundsSlice, entitledFundsSlice) =>
    Object.values(fundsSlice.data ?? {})
      .filter((fund) => {
        const entitledFund = entitledFundsSlice.data?.[fund.id];

        if (!entitledFund) return false;

        return entitledFund.productFamily !== "AlpInvest" && entitledFund.productSegment !== "Investment Solutions";
      })
      .map((fund) => ({
        label: fund.name,
        id: generatePath(AppRoute.FundProfile, { entityID: fund.id }),
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
);
