import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { AppRoute } from "@app/models/AppRoute";
import { Button, DataGrid, NoDataMessage, NoDataTile, Page, Tile } from "@shared/components";
import { fetchInsightsHubArticles } from "@insightsHub/store/thunks";
import {
  insightsHubFiltersSelector,
  insightsHubPageFilterSelector,
  insightsHubMainContentSelector,
  insightsHubSecondaryContentSelector,
} from "@insightsHub/store/selectors";
import { sortedObjectsByDates } from "@utils/DateSorter";
import InsightHubFilters from "@insightsHub/containers/InsightHubFilter";
import { openInvestorRequestDialog } from "@investorRequest/store/ducks";
import { InvestorRequestType } from "@investorRequest/models";
import myContentPageBannerUrl from "@assets/images/ContentLibraryPageBanner.png";
import { useFiltersPannelPined } from "@shared/hooks/useFiltersPannelPined";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { InsightHubsDataRow } from "@insightsHub/models/dataGrid.models";
import { getEST } from "@insightsHub/util/DateFormat";
import { getContentLibDataGridColumns } from "@insightsHub/constants/grid.const";
import InsightsHubMainContent from "@insightsHub/components/InsightsHubMainContent";
import InsightsHubSecondaryContent from "@insightsHub/components/InsightsHubSecondaryContent";

type insightsHubLandingArticle = {
  id: string;
  title: string;
  publishDate: string;
  contentType: string;
  authorName: string;
  url: string;
  description: string;
};
const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: 0,

    [theme.breakpoints.up("xl")]: {
      fontSize: 60,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 35,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 43,
      letterSpacing: 0.63,
    },
  },
  hood: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 339,
    background: `url(${myContentPageBannerUrl}) center center`,
    backgroundSize: "cover",
    letterSpacing: 1.2,
    color: theme.palette.common.white,
  },
  tabs: {
    marginBottom: 54,
  },
  view: {
    overflow: "hidden",
  },
  circularLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
  featureInsightsContainer: {
    gap: "10px 0px",
    // [theme.breakpoints.up("xl")]: {
    //   fontSize: 60,
    // },
    // [theme.breakpoints.between("lg", "xl")]: {
    //   fontSize: 35,
    // },
    // [theme.breakpoints.between("md", "lg")]: {
    //   fontSize: 28,
    // },
    // [theme.breakpoints.between("sm", "md")]: {
    //   fontSize: 24,
    // },

    [theme.breakpoints.down("sm")]: {
      gap: "20px 0px",
      flexDirection: "column",
    },
  },
  secondaryImageContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyContent: "space-between",
    gap: "0px 20px",

    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1fr)",
      // justifyContent: "space-between",
      gap: "20px 0px",
      flexDirection: "column",
    },
  },
}));

const InsightHubPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleReadMore = useCallback(
    (row: InsightHubsDataRow) => {
      // history.push(`${AppRoute.InsightsHub}/${row.id}`);
      history.push({ pathname: `${AppRoute.InsightsHub}/${row.id}`, state: { from: "content-page" } });
    },
    [history]
  );

  useEffect(() => {
    dispatch(fetchInsightsHubArticles());
  }, [dispatch]);

  const { insightsHubFilterValues } = useAppSelector(insightsHubFiltersSelector);

  const articles = useAppSelector(insightsHubPageFilterSelector);
  const insightsHubMainArticle = useAppSelector(insightsHubMainContentSelector);
  const insightsHubSecondaryArticle = useAppSelector(insightsHubSecondaryContentSelector);
  const mainArticles: insightsHubLandingArticle = sortedObjectsByDates(insightsHubMainArticle)[0];
  const secondoryArticles: insightsHubLandingArticle[] = sortedObjectsByDates(insightsHubSecondaryArticle);

  const insightsHubTopThreeSecArticle =
    secondoryArticles.length > 3 ? secondoryArticles.slice(0, 3) : secondoryArticles;

  const sortedData = useMemo(() => {
    return articles.sort((a, b) => {
      return Number(new Date(b.publishDate)) - Number(new Date(a.publishDate));
    });
  }, [articles]);
  const formatedDate = useMemo(() => {
    return sortedData.map((item) => {
      return { ...item, publishDate: format(Date.parse(getEST(item.publishDate)), "MMM d, yyyy") };
    });
  }, [sortedData]);

  const dataRowsInsight: InsightHubsDataRow[] = formatedDate;
  const totalRows = dataRowsInsight.length;

  const classes = useStyles();
  const theme = useTheme();

  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isXsmall = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const { filtersNodeRef } = useFiltersPannelPined(isMedium);

  const [gridData, setGridData] = useState<Array<InsightHubsDataRow>>([]);
  const columns = getContentLibDataGridColumns({ handleReadMore, isXsmall });

  const [rowsToShow, setRowsToShow] = useState<number>(8);

  useEffect(() => {
    if (
      insightsHubFilterValues.selectedAuthor ||
      insightsHubFilterValues.selectedFormat ||
      insightsHubFilterValues.selectedBusinessSegment ||
      insightsHubFilterValues.timeRange.timePeriod.id !== "ALL" ||
      insightsHubFilterValues.searchInputKeyword
    ) {
      setRowsToShow(dataRowsInsight.length);
    } else {
      setRowsToShow(8);
    }
  }, [
    dataRowsInsight.length,
    insightsHubFilterValues.searchInputKeyword,
    insightsHubFilterValues.timeRange.timePeriod,
    insightsHubFilterValues.selectedBusinessSegment,
    insightsHubFilterValues.selectedAuthor,
    insightsHubFilterValues.selectedFormat,
  ]);

  useEffect(() => {
    /* tslint:disable-next-line */
    setGridData((): any => {
      if (dataRowsInsight.length > 0) {
        return dataRowsInsight.slice(0, rowsToShow);
      }
    });
  }, [rowsToShow, dataRowsInsight]);

  const handleNoDataClick = useCallback(() => {
    dispatch(openInvestorRequestDialog({ requestType: InvestorRequestType.NoData }));
  }, [dispatch]);
  const manageInsights = useCallback(() => {
    setRowsToShow((prev) => Math.min(prev + 8, totalRows));
  }, [setRowsToShow, totalRows]);

  return (
    <>
      <Box className={classes.hood}>
        <Typography variant={isMedium ? "h3" : "h2"}>Content Library</Typography>
      </Box>
      <Page>
        <Grid container gap="20px 0px">
          <Grid item>
            <Typography variant="h3" className={classes.title}>
              Featured Insights
            </Typography>
          </Grid>
          <Grid item>
            <Grid container gap="10px 0px" className={classes.featureInsightsContainer}>
              <Grid item xs={12}>
                {mainArticles && (
                  <InsightsHubMainContent
                    id={mainArticles.id}
                    url={mainArticles.url}
                    title={mainArticles.title}
                    publishDate={mainArticles.publishDate}
                    description={mainArticles.description}
                    contentType={mainArticles.contentType}
                    authorName={mainArticles.authorName}
                  />
                )}
              </Grid>
              <Grid item>
                <Grid container direction="row" className={classes.secondaryImageContainer}>
                  {insightsHubTopThreeSecArticle.length > 0 &&
                    insightsHubTopThreeSecArticle.map((article) => {
                      return (
                        <Grid item key={article.id}>
                          <InsightsHubSecondaryContent
                            id={article.id}
                            url={article.url}
                            title={article.title}
                            publishDate={article.publishDate}
                            description={article.description}
                            contentType={article.contentType}
                            authorName={article.authorName}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={classes.view}
            item
            xs={12}
            data-testid="content-library-filters-section"
            ref={filtersNodeRef}
          >
            <Grid container>
              <Grid item xs={12}>
                <Tile>
                  <InsightHubFilters />
                </Tile>
              </Grid>
              <Grid item data-testid="insightshub-events-section" xs={12}>
                <Tile>
                  <>
                    {dataRowsInsight?.length ? (
                      <DataGrid columns={columns} data={gridData} maxHeight={560} />
                    ) : (
                      <NoDataTile noDataMessage={NoDataMessage.General} onContactUsClick={handleNoDataClick} />
                    )}
                  </>
                </Tile>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Page>
      <Box>
        <Button
          size={"large"}
          fullWidth
          color="secondary"
          type="button"
          onClick={manageInsights}
          disabled={rowsToShow >= totalRows}
        >
          view More insights
        </Button>
      </Box>
    </>
  );
};

export default InsightHubPage;
