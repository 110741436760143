import { useMemo } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { AppRoute } from "@app/models/AppRoute";

export const tabRoutes = [
  AppRoute.InsightsHub,
  AppRoute.Documents,
  AppRoute.FundProfile,
  AppRoute.ReportingBase,
  AppRoute.DataRooms,
  AppRoute.MyCalendar,
  AppRoute.AdministrationReports,
  AppRoute.AdministrationImpersonate,
];

const useGetAppNavigationActiveTab = () => {
  const location = useLocation();

  return useMemo(() => {
    const pageMatch = tabRoutes
      .map((path) =>
        matchPath(location.pathname, {
          path,
        })
      )
      .find((match) => match);

    return pageMatch ? pageMatch.path : false;
  }, [location.pathname]);
};

export default useGetAppNavigationActiveTab;
