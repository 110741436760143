import React, { FunctionComponent, memo } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { ContentTypeChipProps } from "./models";

const useStyles = makeStyles<Theme, Pick<ContentTypeChipProps, "fullWidth" | "chipType">>((theme) => ({
  chipContent: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#61A1E0",
    borderRadius: 0,
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 1.2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 1.3,
    },
    padding: theme.spacing(2, 5),
  },
  chipSegment: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(235, 235, 235, 1)",
    borderRadius: 0,
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 1,
    padding: theme.spacing(1, 2),
  },
  chipText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
  },
}));

const ContentTypeChip: FunctionComponent<ContentTypeChipProps> = ({ label, fullWidth, chipType }) => {
  const classes = useStyles({ fullWidth, chipType });
  const testIdFromLabel = label.toLowerCase().replaceAll(" ", "-");

  return (
    <Box className={chipType == "content" ? classes.chipContent : classes.chipSegment}>
      <Box className={classes.chipText} title={label} data-testid={`chip-label-${testIdFromLabel}`}>
        {label}
      </Box>
    </Box>
  );
};

export default memo<ContentTypeChipProps>(ContentTypeChip);
