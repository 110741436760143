import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import CommonUtils from "@shared/utils/CommonUtils";
import { ListOptionBase } from "@shared/components";
import { EntitiesState } from "./models";

export const accountGroupsSelector = (state: RootState): EntitiesState["accountGroups"] => state.entities.accountGroups;

export const entitledFundsSelector = (state: RootState): EntitiesState["entitledFunds"] => state.entities.entitledFunds;

export const entitledValidEndDateFundsSelector = (state: RootState): EntitiesState["entitledValidFunds"] =>
  state.entities.entitledValidFunds;

export const documentTypesSelector = (state: RootState): EntitiesState["documentTypes"] => state.entities.documentTypes;

export const entitledAccountsSelector = (state: RootState): EntitiesState["entitledAccounts"] =>
  state.entities.entitledAccounts;

export const entitledAccountsByFundLoadingSelector = (
  state: RootState
): EntitiesState["entitledAccountsByFundLoading"] => state.entities.entitledAccountsByFundLoading;

export const investorsSelector = (state: RootState): EntitiesState["investors"] => state.entities.investors;

export const fundsSelector = (state: RootState): EntitiesState["funds"] => state.entities.funds;

export const documentSubTypesListOptionsSelector = createSelector(documentTypesSelector, (documentTypesSlice) => {
  const otherIds: Array<number> = [];

  const documentSubTypesListOptions = CommonUtils.convertMapToArray(
    documentTypesSlice.data,
    (documentType) => documentType
  ).reduce((options, documentType) => {
    documentType.documentSubtypes.forEach((documentSubType) => {
      if (documentSubType.name === "Other") {
        otherIds.push(documentSubType.documentSubtypeId);
      } else {
        options.push({
          id: String(documentSubType.documentSubtypeId),
          label: documentSubType.name,
        });
      }
    });

    return options;
  }, [] as Array<ListOptionBase>);

  documentSubTypesListOptions.push({
    id: otherIds.join(", "),
    label: "Other",
  });

  return documentSubTypesListOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
});
