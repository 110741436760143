import { ComponentType, SVGProps } from "react";
import { AppRoute } from "@app/models/AppRoute";
import { AccountSummaryReportName } from "@reporting/models";

export interface FooterLink {
  isActive: boolean;
  label: AccountSummaryReportName | FooterLinkLabel;
  getLink(link?: string): AppRoute | string;
}

export interface FooterSocial {
  label: string;
  link: string;
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
}

export enum FooterLinkLabel {
  DataRooms = "Data Rooms",
  GlobalInsights = "Content Library",
  Reporting = " Reporting",
  Documents = "Documents",
  MyCalendar = "My Calendar",
  FAQs = "FAQs",
}
