import { useEffect, useRef, useState } from "react";
import { MOBILE_HEADER_HEIGHT } from "@app/constants/header";

export const useFiltersPannelPined = (shouldObserve: boolean) => {
  const filtersNodeRef = useRef<HTMLDivElement>(null);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    const filtersNode = filtersNodeRef.current;

    const observer = new IntersectionObserver(
      ([observerEntry]) => {
        if (filtersNode) {
          setIsPinned(observerEntry.intersectionRatio < 1 && observerEntry.boundingClientRect.y < MOBILE_HEADER_HEIGHT);
        }
      },
      {
        threshold: 1,
        rootMargin: `-${MOBILE_HEADER_HEIGHT}px 0px 0px 0px`,
      }
    );

    if (shouldObserve && filtersNode) {
      observer.observe(filtersNode);

      return () => observer.unobserve(filtersNode);
    }
  });

  return {
    filtersNodeRef,
    isPinned,
  };
};
