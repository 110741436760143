import React, { useCallback, useMemo } from "react";
import { isNil, omit, round } from "lodash";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { BaseRow, CellProps, ColumnMeta, HeaderCellProps } from "@shared/components/DataGrid/models";
import { Currency, CurrencySymbol } from "@shared/models/currency";
import { Checkbox } from "@shared/components/Checkbox";
import { ReactComponent as ActionFlagIcon } from "@assets/svg/flag_icon.svg";
import { ReactComponent as DownloadIcon } from "@assets/svg/download_icon.svg";
import { ReactComponent as VideoIcon } from "@assets/svg/video_icon.svg";
import NumberUtils from "@shared/utils/NumberUtils";
import { Button } from "@shared/components/Button";

type StyleProps = Pick<HeaderCellProps<any>, "align" | "width" | "flex" | "type" | "minWidth" | "pinned"> & {
  currency?: Currency;
  interactive: boolean;
  value: boolean;
};

const isNumeric = (type: ColumnMeta<any>["type"]) => ["currency", "number"].some((t) => t === type);

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  cell: {
    alignSelf: "center",
    padding: theme.spacing(0, 2.5),
    position: ({ pinned }) => (pinned ? "sticky" : "relative"),
    left: ({ pinned }) => (pinned ? 0 : "auto"),
    zIndex: ({ pinned }) => (pinned ? 1 : 0),
    backgroundColor: "inherit",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: ({ align }) => align,
    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    flex: ({ flex }) => flex ?? "initial",
    fontFamily: ({ type }) => (isNumeric(type) ? "Segoe" : "inherit"),
    fontWeight: ({ type }) => (isNumeric(type) ? theme.typography.fontWeightMedium : "inherit"),
    transition: "color ease-in .15s",

    "&:hover": {
      cursor: ({ interactive }) => (interactive ? "pointer" : "default"),
      textDecoration: ({ interactive }) => (interactive ? "underline" : "none"),
      color: ({ interactive }) => (interactive ? theme.palette.info.main : theme.palette.primary.main),
    },
  },
  currency: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
  readIndicator: {
    width: 13,
    height: 13,
    background: theme.palette.info.main,
    borderRadius: "50%",
  },
  actionFlag: {
    width: 13,
    height: 13,
    cursor: "pointer",
    color: ({ value }) => (value ? theme.palette.primary.main : theme.palette.grey[500]),
  },
  downloadIcon: {
    width: 14,
    height: 18,
  },
  videoIcon: {
    display: "block",
    width: 20,
    height: 12,
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 18,
    padding: theme.spacing(0, 2.5, 0, 0),

    fontWeight: theme.typography.fontWeightMedium,
  },
  author: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: theme.spacing(0, 2.5, 0, 0),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Cell = <D extends BaseRow>(props: CellProps<D>) => {
  const classes = useStyles({
    pinned: props.column.pinned ?? false,
    align: props.column.align ?? "right",
    width: props.column.width ?? "center",
    flex: props.column.flex ?? "initial",
    type: props.column.type ?? "string",
    currency: props.row.original.rowMeta?.currency,
    interactive: Boolean(props.column.onClick) && !props.row.original.rowMeta?.nonClickableRow,
    value: props.value,
    minWidth: props.column.minWidth,
  });

  const handleClick = useCallback(() => {
    if (props.column.onClick) {
      props.column.onClick(props.row);
    }
  }, [props.column, props.row]);

  const content = useMemo(() => {
    const cellValue = props.row.values[props.column.id];
    //author
    const getCurrencyCellValue = (): string => {
      if (isNil(cellValue)) return "";
      if (!props.column.numberFormat) return cellValue;

      return NumberUtils.formatNumber(round(cellValue, props.column.currencyFractionDigits), props.column.numberFormat);
    };

    const getAuthorCellValue = (): string[] => {
      if (isNil(cellValue)) return [];
      // if (!props.column.numberFormat) return cellValue;
      const insightTitle = (cellValue as string).split("__?");

      return insightTitle;
    };

    if (props.row.original.rowMeta?.cellRenderers?.[props.column.id]) {
      return props.row.original.rowMeta?.cellRenderers?.[props.column.id]?.(props);
    }

    switch (props.column.type) {
      case "readIndicator":
        return !cellValue ? <div data-testid="read-indicator" className={classes.readIndicator} /> : null;
      case "checkbox":
        return props.row.original.rowMeta?.type === "Video" ? (
          <VideoIcon className={classes.videoIcon} />
        ) : (
          <Checkbox checked={props.row.isSelected} onChange={props.row.toggleRowSelected} />
        );
      case "actionFlag":
        return <ActionFlagIcon className={classes.actionFlag} role="actionFlag" />;
      case "currency": {
        const value = getCurrencyCellValue();

        return (
          <Box>
            {Boolean(value) && (
              <span data-testid="currency" className={classes.currency}>
                {CurrencySymbol[props.row.original.rowMeta!.currency!]}
              </span>
            )}
            {value}
          </Box>
        );
      }
      case "author": {
        const value = getAuthorCellValue();

        return (
          <div>
            {value.length > 0 && (
              <div>
                <Box component="div" className={classes.title}>
                  {value[0]}
                </Box>
                <Box component="div" className={classes.author}>
                  {value[1]}
                </Box>
              </div>
            )}
          </div>
        );
      }
      case "download":
        return props.row.original["isLoading"] ? (
          <CircularProgress size={14} />
        ) : (
          <DownloadIcon className={classes.downloadIcon} role="download" data-testid="download-icon" />
        );
      case "learnMore":
        return (
          <Button size={"small"} shape="rounded" onClick={() => false}>
            Read More
          </Button>
        );
      default:
        return props.render("Cell");
    }
  }, [
    props,
    classes.readIndicator,
    classes.author,
    classes.title,
    classes.videoIcon,
    classes.actionFlag,
    classes.downloadIcon,
    classes.currency,
  ]);

  return (
    <div
      {...omit(props.getCellProps(), "style")}
      className={classes.cell}
      onClick={handleClick}
      data-testid="datagrid-cell"
    >
      {props.column.withTooltip && content ? (
        <Tooltip title={content}>
          <Typography variant="bodySubhead3" fontSize={14}>
            {content}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="bodySubhead3" fontSize={14}>
          {content}
        </Typography>
      )}
    </div>
  );
};

export default Cell;
