import React, { useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { debounce } from "lodash";
import { Control, DateRangePicker, Input, Multiselect, Singleselect } from "@shared/components";
// import { documentsFiltersSelector } from "@documents/store/selectors";
// import {
//   useInsightsContentAuthorFilters,
//   // useInsightsResetDateRangeToDefault,
//   // useInsightsTimePeriodFilter,
//   // useInsightsUserFormatFilter,
//   // useInsightsBusinessSegmentFilter,
//   // useInsightsInputKeywordFilter,
// } from "@insightsHub/hooks/filters";
import { useAppSelector, useAppDispatch } from "@app/hooks";
import { insightsHubFiltersSelector } from "@insightsHub/store/selectors";
import { timeRangeOptions } from "@insightsHub/constants";
import useMultiselectHandlers from "@shared/hooks/useMultiselectHandlers";
import { updateInsightFilters } from "@insightsHub/store/ducks";
import { Nullable } from "@shared/models/general";
import { TimeRangeIDs } from "@documents/models/filter.models";

// import { changeDocumentsFilters } from "@documents/store/thunks";
// import { TimeRangeIDs } from "@documents/models/filter.models";

// import { Nullable } from "@shared/models/general";
// import { useGetUserPermissions } from "@user/hooks";
// import { Permission } from "@user/models";

const InsightHubFilters = () => {
  const { contentAuthorOptions, formatOptions, businessSegmentOptions, timeRange, insightsHubFilterValues } =
    useAppSelector(insightsHubFiltersSelector);
  const dispatch = useAppDispatch();

  // const filters = useAppSelector(selectFilters);
  // const permissions = useGetUserPermissions();

  // const { contentAuthorOptions, handleChangeContentAuthors, handleClearAllContentAuthors } =
  //   useInsightsContentAuthorFilters();

  const [handleChangeFormatTypes, handleClearAllFormatTypes] = useMultiselectHandlers({
    entityName: "selectedFormat",
    selectedValues: insightsHubFilterValues.selectedFormat,
    action: updateInsightFilters,
  });

  const [handleChangeContentAuthors, handleClearAllContentAuthors] = useMultiselectHandlers({
    entityName: "selectedAuthor",
    selectedValues: insightsHubFilterValues.selectedAuthor,
    action: updateInsightFilters,
  });

  const [handleChangeBusinessSegments, handleClearAllBusinessSegments] = useMultiselectHandlers({
    entityName: "selectedBusinessSegment",
    selectedValues: insightsHubFilterValues.selectedBusinessSegment,
    action: updateInsightFilters,
  });

  const handleChangeDateRange = useCallback(
    (timePeriod) => dispatch(updateInsightFilters({ timeRange: { ...timeRange, timePeriod } })),
    [dispatch, timeRange]
  );

  const handleStartDateChange = useCallback(
    (date: Nullable<Date>) => dispatch(updateInsightFilters({ timeRange: { ...timeRange, start: date } })),
    [dispatch, timeRange]
  );

  const handleEndDateChange = useCallback(
    (date: Nullable<Date>) => dispatch(updateInsightFilters({ timeRange: { ...timeRange, end: date } })),
    [dispatch, timeRange]
  );

  const changeInputDebounced = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(updateInsightFilters({ searchInputKeyword: value }));
      }, 500),
    [dispatch]
  );

  const handleKeywordChange = useCallback(
    (value: string) => {
      dispatch(updateInsightFilters({ searchInputKeyword: value }));
      changeInputDebounced(value);
    },
    [dispatch, changeInputDebounced]
  );

  return (
    <Grid container spacing={8} data-testid="format-filters">
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Control label="Format">
          <Multiselect
            withSearch
            entity="format"
            options={formatOptions}
            onSelect={handleChangeFormatTypes}
            onClearAll={handleClearAllFormatTypes}
            testId="format-types"
          />
        </Control>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} data-testid="author-control">
        <Control label="Author">
          <Multiselect
            withSearch
            entity="author"
            options={contentAuthorOptions}
            onSelect={handleChangeContentAuthors}
            onClearAll={handleClearAllContentAuthors}
            testId="fund"
          />
        </Control>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} data-testid="business-segment-control">
        <Control label="Business Segment">
          <Multiselect
            withSearch
            entity="business segment"
            options={businessSegmentOptions}
            onSelect={handleChangeBusinessSegments}
            onClearAll={handleClearAllBusinessSegments}
            testId="business-segment-account"
          />
        </Control>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} data-testid="date-range-control">
        <Control label="Date Range">
          <Singleselect
            selectedOption={timeRange.timePeriod}
            options={timeRangeOptions}
            onSelect={handleChangeDateRange}
            testId="date-range"
          />
        </Control>
        {timeRange.timePeriod.id === TimeRangeIDs.EXACTRANGE && (
          <Box sx={{ pt: 2.5 }}>
            <DateRangePicker
              startDate={timeRange.start}
              endDate={timeRange.end}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              testId="insightsHub-time-range"
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Control label="SEARCH BY KEYWORD" testId="calendar-keyword-filter">
          <Input
            search
            placeholder="Enter Text Here"
            value={insightsHubFilterValues.searchInputKeyword + ""}
            onChange={handleKeywordChange}
            testId="calendar-search"
          />
        </Control>
      </Grid>
    </Grid>
  );
};

export default InsightHubFilters;
