import { useAppSelector } from "@app/hooks";
import { entitledFundsSelector } from "@store/Entities/selectors";

export const useAlpInvestEntitleDataValidaion = () => {
  const entitledFunds = useAppSelector(entitledFundsSelector);
  const alpnvestFunds = entitledFunds.data;

  if (!alpnvestFunds) return;
  const filteredEntries = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(alpnvestFunds).filter(([key, entities]) => {
      const endDate = entities.endDate ? new Date(entities.endDate) : new Date();
      const currentDate = entities.currentDate ? new Date(entities.currentDate) : new Date();

      return (
        endDate >= currentDate &&
        entities.productFamily === "AlpInvest" &&
        entities.productSegment === "Investment Solutions"
      );
    })
  );

  return Object.keys(filteredEntries).length ? true : false;
};
