export const getUTC = (date: string) => {
  const timeZoneOffsetMs = new Date(date).getTimezoneOffset() * 60 * 1000;
  const utcDate = new Date(new Date(date).getTime() + timeZoneOffsetMs);

  return utcDate;
};

const options = {
  timeZone: "America/New_York",
};

export const getEST = (date: string | Date) => {
  const dateEst = new Date(date);
  const estTime = new Intl.DateTimeFormat("en-US", options).format(dateEst);

  return estTime;
};
