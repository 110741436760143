import { createSelector } from "@reduxjs/toolkit";
import { InsightsHubArticle, InsightsHubState } from "@insightsHub/models";
import { RootState } from "@store/index";
import { getUTC } from "@insightsHub/util/DateFormat";
import CommonUtils from "@shared/utils/CommonUtils";
import { timeRangeConverter } from "@documents/utils";

export const insightsHubSelector = (state: RootState) => state.insightsHub;
export const insightsHubSliceSelector = (state: RootState): InsightsHubState["data"] => state.insightsHub.data;
export const selectInsightsHubFilterValues = (state: RootState): InsightsHubState["filters"] =>
  state.insightsHub.filters;
export const insightsHubContentAuthorsValueSelector = (state: RootState) => state.insightsHub.filters.selectedAuthor;
export const insightsHubFormatValueSelector = (state: RootState) => state.insightsHub.filters.selectedFormat;
export const insightsHubBusinessSegmentValueSelector = (state: RootState) =>
  state.insightsHub.filters.selectedBusinessSegment;
export const insightsHubTimeRangeValueSelector = (state: RootState) => state.insightsHub.filters.timeRange;
export const insightsHubInputKewordValueSelector = (state: RootState) => state.insightsHub.filters.searchInputKeyword;

export const insightsHubArticleByIdSelector = createSelector(
  [insightsHubSelector, (state, articleId): string => articleId],
  (insightsHubSlice, articleId) => ({
    loading: insightsHubSlice.loading,
    article: (insightsHubSlice.data ?? []).find((article) => String(article.id) === articleId)!,
    error: insightsHubSlice.error,
  })
);

export const insightsHubArticlesSelector = createSelector([insightsHubSelector], (insightsHubSlice) => {
  return (insightsHubSlice.data ?? [])
    .map((article) => ({
      id: article.id,
      title: article.title,
      url: article.homepageImage,
      description: article.shortDescription,
      publishDate: article.publishDate,
      publised_date: article.publised_date,
      contentType: article.contentType,
    }))
    .sort((a, b) =>
      a.title.replace(/\s/g, "").toLocaleLowerCase().localeCompare(b.title.replace(/\s/g, "").toLocaleLowerCase())
    );
});

export const insightsHubArticlesFeaturedSelector = createSelector([insightsHubSelector], (insightsHubSlice) => {
  return (insightsHubSlice.data ?? [])
    .filter((article) => article.featuredArticle && article.homepageImage !== undefined)
    .map((item) => ({
      id: item.id,
      title: item.title,
      url: item.homepageImage,
      description: item.shortDescription,
      publishDate: item.publishDate,
      publised_date: item.publised_date,
      contentType: item.contentType,
    }));
});

export const insightsHubArticlesAuthorsSelector = createSelector([insightsHubSelector], (insightsHubSlice) =>
  Array.from(
    new Set((insightsHubSlice.data ?? []).map((article) => article.authorName).sort((a, b) => a.localeCompare(b)))
  )
);

export const insightsHubArticlesFormatSelector = createSelector([insightsHubSelector], (insightsHubSlice) =>
  Array.from(
    new Set(
      (insightsHubSlice.data ?? [])
        .map((article) => (article.contentType !== "" ? article.contentType : ""))
        .filter((item) => item !== "")
        .sort((a, b) => a.localeCompare(b))
    )
  )
);

export const insightsHubArticlesBusinessSegmentSelector = createSelector([insightsHubSelector], (insightsHubSlice) =>
  Array.from(
    new Set(
      (insightsHubSlice.data ?? []).flatMap((article) => {
        return article.businessSegmenstMultiSelect !== null ? article.businessSegmenstMultiSelect : [];
      })
    )
  ).sort((a, b) => a.localeCompare(b))
);

export const insightsHubArticlesAllAuthorsSelector = createSelector([insightsHubSelector], (insightsHubSlice) =>
  Array.from(
    new Set(
      (insightsHubSlice.data ?? []).flatMap((article) =>
        [article.authorName, article.authorName2, article.authorName3].filter((author) => author != "")
      )
    )
  ).sort((a, b) => a.localeCompare(b))
);

const formatAuthors = (article: InsightsHubArticle) => {
  let authorNames = "";

  if (article.authorName && article.authorName2 && article.authorName3) {
    authorNames = `${article.authorName}, ${article.authorName2} and ${article.authorName3}`;
  } else if (article.authorName && article.authorName2 && !article.authorName3) {
    authorNames = `${article.authorName} and ${article.authorName2}`;
  } else if (article.authorName && article.authorName3 && !article.authorName2) {
    authorNames = `${article.authorName} and ${article.authorName3}`;
  } else if (article.authorName && !article.authorName2 && !article.authorName3) {
    authorNames = `${article.authorName}`;
  }

  return authorNames || "";
};

export const insightsHubPageSelector = createSelector([insightsHubSelector], (insightsHubSlice) => {
  return (insightsHubSlice.data ?? []).map((article) => {
    return {
      id: String(article.id),
      title: article.title,
      publishDate:
        article.publised_date !== "" && getUTC(article.publised_date) <= getUTC(new Date().toISOString())
          ? article.publised_date
          : article.publishDate,
      contentType: article.contentType,
      businessSegment:
        article.businessSegmenstMultiSelect && article.businessSegmenstMultiSelect.length > 1
          ? article.businessSegmenstMultiSelect.slice(0, -1).join(", ") +
            " and " +
            article.businessSegmenstMultiSelect.slice(-1)
          : article.businessSegmenstMultiSelect?.join(""),
      authorName: `${article.title}__?` + formatAuthors(article),
    };
  });
});

export const insightsHubFormatOptionsSelector = createSelector(
  insightsHubFormatValueSelector,
  insightsHubArticlesFormatSelector,
  (selectedContentFormatIds, formats) => {
    const selectedContentFormatsIdsMap = CommonUtils.convertArrayToMap(selectedContentFormatIds);

    return formats.map((format) => ({
      id: format,
      label: format,
      selected: selectedContentFormatsIdsMap[format],
    }));
  }
);
export const insightsHubContentAuthorsOptionsSelector = createSelector(
  insightsHubContentAuthorsValueSelector,
  insightsHubArticlesAuthorsSelector,
  (selectedContentAuthorsIds, authors) => {
    const selectedContentAuthorsIdsMap = CommonUtils.convertArrayToMap(selectedContentAuthorsIds);

    return authors.map((author) => ({
      id: author,
      label: author,
      selected: selectedContentAuthorsIdsMap[author],
    }));
  }
);

export const insightsHubContentAllAuthorsOptionsSelector = createSelector(
  insightsHubContentAuthorsValueSelector,
  insightsHubArticlesAllAuthorsSelector,
  (selectedContentAuthorsIds, authors) => {
    const selectedContentAuthorsIdsMap = CommonUtils.convertArrayToMap(selectedContentAuthorsIds);

    return authors.map((author) => ({
      id: author,
      label: author,
      selected: selectedContentAuthorsIdsMap[author],
    }));
  }
);

export const insightsHubContentBusinessSegmentsOptionsSelector = createSelector(
  insightsHubBusinessSegmentValueSelector,
  insightsHubArticlesBusinessSegmentSelector,
  (selectedBusinessSegmentsIds, authors) => {
    const selectedContentBusinessSegmentsIdsMap = CommonUtils.convertArrayToMap(selectedBusinessSegmentsIds);

    return authors.map((author) => ({
      id: author,
      label: author,
      selected: selectedContentBusinessSegmentsIdsMap[author],
    }));
  }
);

export const insightsHubFiltersSelector = createSelector(
  insightsHubFormatOptionsSelector,
  insightsHubContentAllAuthorsOptionsSelector,
  insightsHubContentBusinessSegmentsOptionsSelector,
  insightsHubTimeRangeValueSelector,
  selectInsightsHubFilterValues,
  (formatOptions, contentAuthorOptions, businessSegmentOptions, timeRange, insightsHubFilterValues) => ({
    formatOptions,
    contentAuthorOptions,
    businessSegmentOptions,
    timeRange,
    insightsHubFilterValues,
  })
);

export const insightsHubPageFilterSelector = createSelector(
  [
    insightsHubPageSelector,
    insightsHubContentAuthorsValueSelector,
    insightsHubFormatValueSelector,
    insightsHubBusinessSegmentValueSelector,
    insightsHubTimeRangeValueSelector,
    insightsHubInputKewordValueSelector,
  ],
  (
    insightsHubPageSlice,
    insighstHubAuthorFilterSilce,
    insightsHubFormatFilterSlice,
    insightsHubBusinessSegmentFilterSlice,
    insightsHubTimeRangeFilterSlice,
    insightsHubInputKeywordFilterSlice
  ) => {
    if (
      !insighstHubAuthorFilterSilce &&
      !insightsHubFormatFilterSlice &&
      !insightsHubBusinessSegmentFilterSlice &&
      insightsHubTimeRangeFilterSlice.timePeriod.id === "ALL" &&
      !insightsHubInputKeywordFilterSlice
    )
      return insightsHubPageSlice;

    return (insightsHubPageSlice ?? []).filter((article) => {
      const { start, end } = timeRangeConverter(insightsHubTimeRangeFilterSlice);
      const authorNames = article.authorName
        .split("__?")[1]
        .split(/\s+and\s+/)
        .flatMap((name) => name.split(",").map((subName) => subName.trim()));

      const filterAuthor = insighstHubAuthorFilterSilce
        ? insighstHubAuthorFilterSilce?.some((author) => authorNames.includes(author.trim()))
        : true;
      const filterBusinessSegment = insightsHubBusinessSegmentFilterSlice
        ? insightsHubBusinessSegmentFilterSlice?.some((segment) => article.businessSegment?.includes(segment))
        : true;
      const filterFormat = insightsHubFormatFilterSlice
        ? insightsHubFormatFilterSlice?.includes(article.contentType)
        : true;
      const filterDate =
        start && end ? getUTC(article.publishDate) >= start && getUTC(article.publishDate) <= end : true;

      const filterInputKeyword = insightsHubInputKeywordFilterSlice
        ? article.authorName?.toLowerCase().includes(insightsHubInputKeywordFilterSlice.toLowerCase()) ||
          article.contentType?.toLowerCase().includes(insightsHubInputKeywordFilterSlice.toLowerCase()) ||
          article.businessSegment
            ?.toString()
            .toLowerCase()
            .includes(insightsHubInputKeywordFilterSlice.toLowerCase()) ||
          article.title.toLowerCase().includes(insightsHubInputKeywordFilterSlice.toLowerCase())
        : true;

      return filterAuthor && filterFormat && filterBusinessSegment && filterDate && filterInputKeyword;
    });
  }
);

export const insightsHubArticlesAuthorTitlesSelector = createSelector([insightsHubSelector], (insightsHubSlice) =>
  Array.from(new Set((insightsHubSlice.data ?? []).map((article) => article.authorTitle)))
    .filter((item) => item !== "")
    .sort((a, b) => a.localeCompare(b))
);

export const insightsHubMainContentSelector = createSelector([insightsHubSelector], (insightsHubSlice) => {
  return (insightsHubSlice.data ?? [])
    .filter(
      (article) =>
        article.featuredArticle && article.articleType === "main article" && article.homepageImage !== undefined
    )
    .map((article) => {
      return {
        id: String(article.id),
        title: article.title,
        publishDate:
          article.publised_date !== "" && getUTC(article.publised_date) <= getUTC(new Date().toISOString())
            ? article.publised_date
            : article.publishDate,
        contentType: article.contentType,
        authorName: formatAuthors(article),
        url: article.homepageImage,
        description: article.shortDescription,
      };
    });
});

export const insightsHubSecondaryContentSelector = createSelector([insightsHubSelector], (insightsHubSlice) => {
  return (insightsHubSlice.data ?? [])
    .filter(
      (article) =>
        article.featuredArticle && article.articleType === "secondary article" && article.homepageImage !== undefined
    )
    .map((article) => {
      return {
        id: String(article.id),
        title: article.title,
        publishDate:
          article.publised_date !== "" && getUTC(article.publised_date) <= getUTC(new Date().toISOString())
            ? article.publised_date
            : article.publishDate,
        contentType: article.contentType,
        authorName: formatAuthors(article),
        url: article.homepageImage,
        description: article.shortDescription,
      };
    });
});
