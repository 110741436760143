import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dropdown, List, SelectToggleButton } from "@shared/components";
import { ReportingNavigationSection } from "@reporting/models";

interface Props {
  className: string;
  dropdownLabel: string;
  dropdownSections: Array<ReportingNavigationSection>;
  onDropdownOptionClick(event: void, route: string): void;
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 2.4,
    padding: theme.spacing(4, 5, 0),
  },
}));

const AppNavigationReportingDropdown = forwardRef<HTMLDivElement, Props>((props, outerRef) => {
  const classes = useStyles();

  return (
    <div ref={outerRef} className={props.className}>
      <Dropdown
        width={250}
        renderToggle={({ ref, open, toggleDropdown }) => (
          <SelectToggleButton ref={ref} open={open} variant="transparent" size="small" onClick={toggleDropdown}>
            {props.dropdownLabel}
          </SelectToggleButton>
        )}
      >
        {({ closeDropdown }) => (
          <Box data-testid="list-container">
            {props.dropdownSections.map((section) => (
              <div key={section.id}>
                {props.dropdownSections.length > 0 && <div className={classes.sectionTitle}>{section.label}</div>}
                <List
                  variant="singleselect"
                  size="small"
                  options={section.options.filter((option) => option.isActive)}
                  onOptionClick={(option) => {
                    closeDropdown();

                    props.onDropdownOptionClick(undefined, option.id as string);
                  }}
                />
              </div>
            ))}
          </Box>
        )}
      </Dropdown>
    </div>
  );
});

AppNavigationReportingDropdown.displayName = "forwardRef(AppNavigationReportingDropdown)";

export default AppNavigationReportingDropdown;
