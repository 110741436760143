export enum Param {
  Currency = "Destination Currency:ISOCode",
  Date = "Date:ActualDate",
  PortfolioType = "Performance:PortfolioType",
  Investor = "Performance:InvestorName",
  Fund = "Performance:FundName",
  MandateFund = "Performance:MandateFund",
  GeneralPartner = "Performance:GeneralPartner",
  Region = "Performance:RegionforReporting",
  SecondaryDeal = "Performance:SecondaryDealProjectName",
  DealStrategy = "Performance:DealStrategy",
  VintageYear = "Performance:VintageYear",
  DefaultPeriod = "Time Intelligence:Name",
  Filter = "CrossSlicer:Identifier",
}
